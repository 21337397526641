import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const links = [
  {
    to: '/',
    title: 'Home',
  },
  {
    to: '/soprano/',
    title: 'Soprano',
  },
  {
    to: '/voice-studio',
    title: 'Voice Studio',
  },
  {
    to: '/gallery',
    title: 'Gallery',
  }
];

const Header = ({ siteTitle }) => (
  <header
    style={{
      marginTop: '-3rem',
      marginBottom: `1.45rem`,
      position: 'fixed',
      background: 'white',
      width: '100%',
      zIndex: 100,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      {links.map(({ to, title }) => (
          <Link
            key={to}
            to={to}
            style={{
              color: `black`,
              textDecoration: `none`,
              margin: '0.5em',
            }}
          >
            {title}
          </Link>
      ))}
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
